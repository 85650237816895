import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';

import { ContentPage } from 'src/app/components/contentPage';

import { reasonBlockingService } from 'src/services/reasonBlocking.service';
import { execToast } from 'src/_metronic/_partials/controls/Toast';

import './create.style.sass'
import { useIntl } from 'react-intl';
import { customInputService } from 'src/services/customInput.service';
import { typeCustomInputsEnums } from './typesCustomInputs';



function convertInBoolean(value: 'yes' | 'no') {
  if (value === 'yes') return true
  return false
}

type IrenderSelected = {
  value: string,
  alterValue(value: string): void,
  listValues?: { label: string, value: string }[],
  block: boolean
}

function targetUser_enum(value: number) {
  switch (value) {
    case 1: {
      return 'Person'
    }

    case 2: {
      return 'Visitant'
    }

    default: {
      return 'Both'
    }
  }
}
export function CustomInputsListCreate() {
  const intl = useIntl()
  const history = useHistory()
  const params = useParams()

  const optionsInputTarget = [
    { label: intl.formatMessage({ id: "MANAGEMENT_TYPES_CREDITS_GRID_COLUMN_ONE" }), value: "Both" },
    { label: intl.formatMessage({ id: "ASIDE.MENU.ITEM.PERSON" }), value: 'Person' },
    { label: intl.formatMessage({ id: "ASIDE.MENU.ITEM.VISITORS" }), value: "Visitant" },
  ]

  const [personInput, setPersonInput] = useState<'yes' | 'no'>('no')
  const [vistorInput, setVisitorInput] = useState<'yes' | 'no'>('no')
  const [validation, setValidation] = useState<'yes' | 'no'>('no')
  const [name, setName] = useState<string>('')
  const [type, setType] = useState('Text')

  const [targetUser, setTargetUser] = useState('Both')
  async function handleSave(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    try {
      const payload = {
        id: 0,
        "name": name,
        "fieldType": type,
        "table": "Person",
        "targetUser": targetUser,
        "requiredForPerson": convertInBoolean(personInput),
        "requiredForVisitor": convertInBoolean(vistorInput),
        "validationRequired": convertInBoolean(validation),
        "isEnabled": true
      }

      if ((params as any).id) {
        await customInputService.edit({
          ...payload,
          id: (params as any).id,
          isEnabled: true
        })

        execToast('success', intl.formatMessage({ id: 'PAGE.BLOCKING.REASONS.EDIT' }), 3000);
      } else {
        await customInputService.save(payload)

        execToast('success', intl.formatMessage({ id: 'PAGE.BLOCKING.REASONS.CREATE' }), 3000);
      }




      history.push('/custom-field')
    } catch (e) {

      console.log({ e })

      let msg = 'PAGE.CUSTOM.INPUT.CREATE.ERROR'

      if (e.response.data.message === '410') {
        msg = 'NOTIFICATION_TOAST_ERROR_DESCRIPION'
      }
      execToast('error', intl.formatMessage({ id: msg }), 3000);
    }
  }

  async function load(id: number) {
    const data = await customInputService.getById(id)

    setPersonInput(data.data.data.requiredForPerson ? 'yes' : 'no')
    setVisitorInput(data.data.data.requiredForVisitor ? 'yes' : 'no')
    setValidation(data.data.data.validationRequired ? 'yes' : 'no')
    setName(data.data.data.name)


    setType(typeCustomInputsEnums(data.data.data.fieldType))

    setTargetUser(targetUser_enum(data.data.data.targetUser))
  }

  function renderSelected({ alterValue, value, listValues = [], block }: IrenderSelected) {
    return (
      <select name="" id="" className='form-control' value={value} onChange={e => alterValue(e.target.value)} disabled={block}>
        {
          listValues.length ? (
            listValues.map(item => (
              <option value={item.value}>{item.label}</option>
            ))
          ) : (
            <>
              <option value="no">{intl.formatMessage({ id: 'LOGREGISTER.TABLE.VALUE.NOT_ACTIVED' })}</option>
              <option value="yes">{intl.formatMessage({ id: 'LOGREGISTER.TABLE.VALUE.ACTIVED' })}</option>
            </>
          )
        }

      </select>
    )
  }


  useEffect(() => {
    if (targetUser === 'Person') setVisitorInput('no')
    if (targetUser === 'Visitant') setPersonInput('no')
  }, [targetUser])

  useEffect(() => {
    if ((params as any).id) {
      load(+(params as any).id)
    }
  }, [])

  return (
    <ContentPage
      title={(params as any).id ? intl.formatMessage({ id: 'PAGE.CUSTOM.INPUTS.EDIT' }) : intl.formatMessage({ id: 'PAGE.CUSTOM.INPUTS.ADD' })}
      addItemTitle={intl.formatMessage({ id: 'PAGE.CUSTOM.INPUTS.ADD' })}
      linkPageCreate=""
      LongFilters={{
        culture: '',
        pageNumber: 1,
        pageSize: 1000,
        sortField: '',
        sortOrder: 'description',
        status: 1
      }}
      logUrl='/reason-for-blocking/create'
      logTotalItens={0}
      logNameFile='Motivos bloqueios'
      logHeaders={[]}
      plusOptionsInToobar={false}
      plusOptionsRender={<></>}
      disabledOptionsHeader={true}
    >
      <form id="FormCreate" className='card-body' onSubmit={handleSave}>
        <div className='row'>
          <div className="col-lg-6 col-xl-6">
            <label htmlFor="">{intl.formatMessage({ id: "NAME_LABEL" })}</label>
            <input type="text" className='form-control' value={name} onChange={e => setName(e.target.value)} />
          </div>

          <div className="col-lg-6 col-xl-6">
            <label
              htmlFor=""
              style={{ display: 'block', marginBottom: "5px" }}
            >
              {intl.formatMessage({ id: "Escolha onde exibir o campo Pessoas, Visitantes ou Ambos" })}
            </label>

            {
              renderSelected({
                value: targetUser,
                alterValue: (value: string) => setTargetUser(value),
                listValues: optionsInputTarget,
                block: false
              })
            }

          </div>



        </div>

        <div className='row mt-8'>
          <div className="col-lg-4 col-xl-4">
            <label
              htmlFor=""
            >
              {intl.formatMessage({ id: "CONFIG.DOCUMENT.REQUIRED.FOR.PERSON" })}
            </label>

            {
              renderSelected({
                value: personInput,
                alterValue: (value: 'yes' | 'no') => setPersonInput(value),
                block: targetUser === 'Visitant'
              })
            }

          </div>

          <div className="col-lg-4 col-xl-4">
            <label
              htmlFor=""
              style={{ display: 'block', marginBottom: "5px" }}
            >
              {intl.formatMessage({ id: "CONFIG.DOCUMENT.REQUIRED.FOR.VISITOR" })}
            </label>

            {
              renderSelected({
                value: vistorInput,
                alterValue: (value: 'yes' | 'no') => setVisitorInput(value),
                block: targetUser === 'Person'
              })
            }

          </div>

          <div className="col-lg-4 col-xl-4">
            <label htmlFor="">{intl.formatMessage({ id: "ACCESS_LOGS.BLACK_LIST.TYPE" })}</label>
            <select name="" id="" value={type} className='form-control' onChange={e => setType(e.target.value)}>
              <option value="Text">{intl.formatMessage({ id: 'PAGE.CUSTOM.INPUTS.TYPE.TEXT' })}</option>
              <option value="Number">{intl.formatMessage({ id: 'PAGE.CUSTOM.INPUTS.TYPE.NUMBER' })}</option>
              <option value="Alphanumeric">{intl.formatMessage({ id: 'PAGE.CUSTOM.INPUTS.TYPE.ALPHANUMERIC' })}</option>
            </select>

          </div>

        </div>

        <div className="col-lg-12 col-xl-12 mt-5 action">
          <div className='align-right'>
            <button type="button" className='btn btn-danger mr-5' onClick={() => {
              history.goBack()
            }}>{intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}</button>
            <button
              type="submit"
              className='btn btn-primary btn-elevate'>
              {
                (params as any).id ? intl.formatMessage({ id: 'EDIT' }) : intl.formatMessage({ id: 'PERSON.NEWPERSON_SAVE_BUTTON' })
              }
            </button>
          </div>
        </div>
      </form>
    </ContentPage>
  )
} 