import React, { useCallback, useState } from 'react';
import { Button, Container, Modal, Row, Table } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { UPLOAD_CSV } from 'src/services/downloadCSV';
import exemplo from './exemplo-bloqueio.csv';


import { execToast } from './Toast';

import { setTimeout } from 'timers';



import './UploadCSVBrockPersonStyles.sass'

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};
export function UploadCSVBrockPerson(props: any) {
  const history = useLocation()
  const currentModule = history.pathname

  const [isLoading, setIsLoading] = useState(false)
  const [isErrorImport, setIsErrorImport] = useState(false)

  const [remoteLink, setRemoteLink] = useState(false)
  const [qrcode, setQrCode] = useState(false)

  const intl = useIntl();
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [switchSectionContent, setSwitchSectionContent] = useState(false);


  const [viewBodyModal, setViewBoryModal] = useState<'file' | 'table'>('file')
  const [tableBodyValues, setTableBodyValues] = useState<{
    id: number,
    nameInDb: string,
    nameInList: string
  }[]>([])

  const [tableValuesInError, setTableValuesInError] = useState<any[]>([])

  const saveFile = (e: any) => {
    setFile(e);
    setFileName(e.name);
  };

  const uploadFile = async (confirm = false) => {
    setIsLoading(true)
    setViewBoryModal('table')

    setSwitchSectionContent(false);
    // props.onHide()
    execToast('success', intl.formatMessage({ id: 'PERSONS.FINGERPRINTS.MODAL.PROCESSING' }), 3000);

    try {

      const path = '/blockedPerson/import'
      const response = await UPLOAD_CSV(confirm, file, fileName, confirm, path, remoteLink, qrcode);

      console.log({ response })

      //response.data.success
      if (response.data.data.length > 0) {
        setTimeout(() => {

          execToast('error', intl.formatMessage({ id: 'SHOW.ERROR.IN.FILE.CSV' }), 3000);

          setTableValuesInError([
            ...response.data.data
          ])
          setIsErrorImport(true)
        }, 500)
        return
      }


      window.controlID.modules.blockedPeople.service.load()

      setTimeout(async () => {
        execToast('success', intl.formatMessage({ id: 'IMPORT.CONFIRM.TITLE' }), 3000);


        props.onHide();


        setIsLoading(false)
      }, 1000)

    } catch (ex) {
      setIsLoading(false)
      setViewBoryModal('file')
      // props.onHide();
      execToast('error', intl.formatMessage({ id: 'TOAST.UPLOAD.ERROR' }), 3000);
      console.error(ex);
    }
  };

  function errorInImport(retorno: any) {
    // console.log(retorno)

    if (!retorno.response) {
      return
    }

    if (retorno.response.status === 422) {
      const message = retorno.response.data.message

      if (typeof message === "string" && message.includes('461')) {
        execToast('error', intl.formatMessage({ id: 'TOAST.PERSON.ERROR.454' }) + message.split('|').filter((_: string, index: number) => index > 0), false);
      }

      if (typeof message === "string" && message.includes('462')) {
        execToast('error', message.split('|').filter((_: string, index: number) => index > 0) + ' ' + intl.formatMessage({ id: 'TOAST.PERSON.ERROR.462' }), false);
      }

      setViewBoryModal('file')
    }

    const msg = retorno.response.data.message
    if (msg === "453") {
      execToast('error', intl.formatMessage({ id: 'TOAST.PERSON.DOCUMENT' }), false);
      setViewBoryModal('file')
      return
    }
  }

  function toPage() {
    window.open(`${window.location.origin}/people`)
  }

  const onDrop = useCallback((acceptedFiles: any[]) => {
    saveFile(acceptedFiles[0]);
    setFileName(acceptedFiles[0].name);
    setSwitchSectionContent(true);
  }, []);



  return (
    <Modal

      {...props}
      aria-labelledby='contained-modal-title-vcenter'
      size='lg'
      onHide={() => {
        props.onHide();
      }}
      id="UploadCSVBrockPerson"
    >
      <Modal.Header closeButton={true}>
        <Modal.Title id='contained-modal-title-vcenter'>
          {intl.formatMessage({ id: 'PAGE.LIST.BLOCKING.TITLE.IMPORT' })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='show-grid'>


        <Container>

          {
            !isErrorImport && (
              <Row>
                {switchSectionContent ? (
                  <section className='container' style={{ padding: '0 11px' }}>
                    <h5>{intl.formatMessage({ id: 'IMPORT.PERSON.MODAL.SUCESS-MENSAGE' })}</h5>
                    <p>{fileName}</p>

                  </section>
                ) : (
                  <>

                    <section className='container' style={{ padding: '0 11px' }}>

                      <span>
                        <strong>
                          {intl.formatMessage({ id: 'LIST.BLOCK.IMPORT.ALERT' })}:
                        </strong>
                        {intl.formatMessage({ id: 'LIST.BLOCK.IMPORT.MSG' })}
                        <strong>ID Number</strong>
                        {intl.formatMessage({ id: "LIST.BLOCK.IMPORT.OR" })} <strong>Matricula</strong> {intl.formatMessage({ id: "LIST.BLOCK.IMPORT.IN.MODEL" })} </span>
                      <div className='d-flex justify-content-center'>
                        <a
                          className='col-12'
                          href="/media/exemplo-bloqueio.csv"
                          download={'exemplo.csv'}>
                          <Button className='col-12 mt-5'>
                            {intl.formatMessage({ id: 'IMPORT.PERSON.MODAL.EXAMPLE.BUTTON.CSV' })}
                          </Button>
                        </a>
                      </div>

                      <h5 className='mt-3'>{intl.formatMessage({ id: 'IMPORT.PERSON.SUBMIT.TITLE' })}</h5>

                      <Dropzone
                        onDrop={onDrop}
                        maxFiles={1}
                        multiple={false}
                        maxSize={268435456} //250BM
                        accept={{

                          'text/csv': ['.csv']
                        }}>
                        {({ getRootProps, getInputProps, isFocused, isDragAccept, isDragReject }) => (
                          <div
                            {...getRootProps({
                              className: 'dropzone',
                              style: {
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '30px',
                                borderWidth: 2,
                                borderRadius: 2,
                                borderColor: '#eeeeee',
                                borderStyle: 'dashed',
                                backgroundColor: '#fafafa',
                                color: '#bdbdbd',
                                outline: 'none',
                                transition: 'border .24s ease-in-out',
                                ...(isFocused ? focusedStyle : {}),
                                ...(isDragAccept ? acceptStyle : {}),
                                ...(isDragReject ? rejectStyle : {})
                              }
                            })}>
                            <input {...getInputProps()} />
                            <p className='m-0'>{intl.formatMessage({ id: 'IMPORT.PERSON.DRAGDROP' })}</p>
                          </div>
                        )}
                      </Dropzone>
                    </section>
                  </>

                )}
              </Row>

            )
          }


          {
            isErrorImport && (
              <Row style={{
                maxHeight: "600px",
                overflowY: "auto"
              }}>
                <>
                  <Container>
                    <Row>
                      <h5>{intl.formatMessage({ id: 'IMPORT.ERROR.CSV.BLOCK.LIST.ITEMS' })}</h5>
                    </Row>
                  </Container>



                  <Table>
                    <thead>
                      <tr>
                        <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                          {intl.formatMessage({ id: 'Person' })}
                        </th>
                        <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                          {intl.formatMessage({ id: 'PAGE.BLOCKING.REASONS' })}
                        </th>
                        <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                          {intl.formatMessage({ id: 'SCHEDULE.SCHEDULE.DESCRIPTION' })}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        tableValuesInError.map(item => (

                          <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.value}</td>
                            <td>{item.error}
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>



                </>
              </Row>
            )
          }

        </Container>






      </Modal.Body>
      <Modal.Footer>

        {
          isErrorImport ?
            (
              <Button variant='primary' disabled={!file} onClick={() => {
                setIsErrorImport(false)
                setFile('')
                setFileName('')
                setSwitchSectionContent(false)
              }}>
                {intl.formatMessage({ id: 'Importar novamente' })}
              </Button>
            ) : (
              <Button variant='primary' disabled={!file} onClick={() => {
                uploadFile()
              }}>
                {intl.formatMessage({ id: 'PERSON.NEWPERSON_UPLOAD' })}
              </Button>
            )
        }

        <Button
          type='button'
          variant='secondary'
          onClick={() => {
            props.onHide();
          }}>
          {intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}
        </Button>
      </Modal.Footer>
    </Modal >
  );
}
