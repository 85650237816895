import { Table } from 'react-bootstrap';
import styled from 'styled-components';

export const TableContainer = styled.ul`
  padding: 0px;
  margin: 0px;

  li {
    list-style: none;
    display: flex;
    padding: 0.75rem;

    span {
      /* width: 350px; */
      min-width: 300px;
      max-width: 300px;
      display: block;

      strong {
        font-weight: 600;
        color: #b5b5c3 !important;
        font-size: 0.9rem;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
      }
    }

    &.item-table {
      border-top: 1px solid #ebedf3;
      cursor: pointer;
      &:hover {
        /* background: #3699FF;
        color:#fff */
      }
    }
  }

  div {
    max-height: 250px;
    overflow-y: scroll;
  }
`;

export const TableContainerB = styled(Table)`
  td {
    cursor: pointer;
    &.green {
      color: #64BEB5;
    }

    &.red {
      color: #BE6664
    }
  }
`;

export const Container = styled.div`
 

  @media (max-height: 728px) {
    max-height: 20.5vh;
    overflow-y: scroll;
  }

  @media (min-height: 729px) and (max-height: 863px) {
    /* max-height: 35vh; */
    max-height: 53vh;
    overflow-y: scroll;
  }


  @media (min-height: 863px) and (max-height: 970px) {
    max-height: 60vh;
    overflow-y: scroll;
  }

  
  @media (min-height: 971px) and (max-height: 1120px) {
    max-height: 54vh;
    overflow-y: scroll;
    /* background: green; */
  }

  @media (min-height: 971px) and (max-height: 1120px) {
    max-height: 67vh;
    overflow-y: scroll;
    /* background: red; */
  }
`;
