import styled, { css } from 'styled-components';

type TWrapperProps = {
  type: 'user' | 'device' | 'skeleton' | 'expiration';
};

const getColorsScheme = (type: TWrapperProps['type']) => {
  if (type === 'user')
    return `
    --bg-color: #C9F7F5;
    --text-color: #1BC5BD;
  `;

  if (type === 'device')
    return `
    --bg-color: #E1F0FF;
    --text-color: #3699FF;
  `;

  if (type === 'expiration')
    return `
    --bg-color: #FFF4DE;
    --text-color: #FFA800;
  `;

  return `
    --bg-color: #f3f4f6;
    --text-color: #f3f4f6;
  `;
};

export const Wrapper = styled.div<TWrapperProps>(
  (props) => `
  display: flex;
  flex-direction: column;
  gap: 32px;

  padding: 24px;
  border-radius: 16px;

  ${getColorsScheme(props.type)}

  color: var(--text-color);
  background-color: var(--bg-color);


  h1 {
    font-size: 24px;
    font-weight: bold;

    margin: 0;
    line-height: 1;
  }
  
  span {
    font-size: 18px;

    margin: 0;
    line-height: 1;
  }

  .sub-title {
    display: flex;
    gap: 5px
  }

  .CircularProgressbar-path {
    stroke: var(--text-color);
  }

  ${props.type === 'skeleton' &&
    css`
      align-items: center;
      & div {
        width: 100%;
      }

      .bar {
        height: 25px;
        background-color: #d1d5db;
        border-radius: 4px;
      }
    `}
`
);

export const Body = styled.div`
  align-self: center;

  width: 150px;
  aspect-ratio: 1;
`;

export const Footer = styled.span`
  align-self: center;

  display: flex;
  align-items: end;
  gap: 10px;
`;
