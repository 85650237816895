import { getAllPersons } from 'src/services/personsCrud';
import { format } from 'date-fns'

//@ts-ignore
import { CSSObjectWithLabel } from 'react-select';
import { reasonBlockingService } from 'src/services/reasonBlocking.service';

class ListBlockPeopleFunctions {
  selectStyle = {
    control: (styles: CSSObjectWithLabel) => ({
      ...styles,
      border: '1px solid #E4E6EF',
      '&:hover': {
        cursor: 'pointer'
      }
    })
  };
  async getPerson(...args: any[]) {
    const [value, params] = args;
    const page = params?.page || 1;

    const {
      data: {
        data: { data: people, pages }
      }
    } = await getAllPersons({
      pageSize: 100,
      pageNumber: page,
      status: 2,
      value: !!value ? value : undefined,
      sortOrder: 'asc',
      sortField: 'Name',
    });

    return {
      options: people.map((el: any) => ({ value: el.id, label: el.name })),
      hasMore: page < pages,
      additional: {
        page: page + 1
      }
    };
  };

  async getReasonBlock(...args: any[]) {
    const [value, params] = args;
    const page = params?.page || 1;

    const paramsToRequest = {
      value: !!value ? value : undefined,
      pageSize: 100,
      pageNumber: page,
      status: 1,
      sortOrder: 'desc',
      sortField: 'description',
    }

    const { data } = await reasonBlockingService.list(paramsToRequest)
    const values = data.data.data.map((item: any) => {
      return {
        value: item.id,
        label: item.description,
      }
    })

    return {
      options: values,
      hasMore: page < data.data.pages,
      additional: {
        page: page + 1
      }
    }
  }

  transformDateToDefaultApi(date: Date) {
    return format(date, 'yyyy-MM-dd HH:mm:ss').replace(' ', 'T')
  }

}

export const listBlockPeopleFunctions = new ListBlockPeopleFunctions()