import axios from 'axios';
import { defaultDateTime } from 'src/utils/defaultDateTime';

export const idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL;
export const devicesAPI = `${idsecureAPI}/devices`;

interface queryParams {
  deviceTypes: string;
  pageSize: number;
  pageNumber: number;
  status: number;
  // value : string;
  sortOrder: string;
  sortField: string;
}

export type GetProps = {
  pageSize: number;
  sortField: string;
  pageNumber: number;
  sortOrder: 'asc' | 'desc';
  status: number;
  value?: string;
};

export function createDevice(device: any) {
  return axios.post(`${idsecureAPI}/devices`, { ...device });
}

export async function uploadDeviceImage(device: any, deviceId: number) {
  const data = new FormData();
  data.append('file', device);
  return await axios.post(`${idsecureAPI}/devices`, data);
}

export function getAllDevices(params: any) {
  return axios.get(`${idsecureAPI}/devices`, { params });
}

export function getDeviceById(deviceId: number) {
  return axios.get(`${idsecureAPI}/devices/${deviceId}`);
}

export function findDevices(params: any) {
  return axios.get(`${idsecureAPI}/devices`, { params });
}

export function findDevicesModel() {
  return axios.get(`${idsecureAPI}/devices/models`);
}

export function updateDevice(device: any) {
  return axios.put(`${idsecureAPI}/devices`, { ...device });
}

export function deleteDevice(deviceId: number, erase?: boolean) {
  return axios.delete(`${idsecureAPI}/devices/${deviceId}`, {
    params: {
      erase
    }
  });
}

export function enableDevice(deviceId: number) {
  return axios.put(`${idsecureAPI}/devices/${deviceId}`);
}

export function openDeviceDoor(deviceId: number, option: number) {
  return axios.post(`${idsecureAPI}/devices/${deviceId}/set/${option}`);
}

export function ownerShipCode(deviceId: number, codigo: number) {
  return axios.post(`${idsecureAPI}/devices/${deviceId}/ownership/${codigo}`);
}

export function statusSyncDevice(data: string[]) {
  return axios.post(`${idsecureAPI}/devices/realtime-status`, data, {
    transformResponse: [function (data) {

      const response = JSON.parse(data)
      try {
        const transformValues = response.map((v: any) => {
          return {
            ...v,
            lastSync: defaultDateTime.labelDateTime(v.lastSync),
            lastConnection: defaultDateTime.labelDateTime(v.lastConnection)
          }
        })

        // response = transformValues

        return transformValues

      } catch {
        return response
      }


    }],
  });
}

export function findAreas(params: any) {
  return axios.get(`${idsecureAPI}/devices/areas`, { params });
}

export function findParkings(params: any) {
  return axios.get(`${idsecureAPI}/devices/areas`, { params });
}

// CHANGED POLICY
export function getAllParks(props: GetProps) {
  return axios.get(`${idsecureAPI}/devices/parkingLot`, {
    params: {
      pageSize: 1000,
      sortField: 'Id',
      sortOrder: 'desc',
      status: 1
    }
  });
}

export function initRemoteRegister(data: any) {
  return axios.post(`${idsecureAPI}/devices/enrollCredential`, data);
}

export function getRemoteRegisterStatus(params: any) {
  return axios.get(`${idsecureAPI}/devices/enrollingStatus`, { params });
}

export function getRealTimeStatus(data: any) {
  return axios.post(`${idsecureAPI}/devices/realtime-status`, data);
}

export function getDeviceState(data: any) {
  return axios.post(`${idsecureAPI}/devices/deviceState`, data);
}

export function saveCredentials(data: any) {
  return axios.post(`${idsecureAPI}/devices/saveCredentials`, data);
}

export function cancelRemoteRegister(data: any) {
  return axios.post(`${idsecureAPI}/devices/cancelEnrolling`, data);
}

export enum ExceptionMode {
  disconnect = 0,
  normal = 1,
  emergency = 2,
  lockdown = 3
}

type TSetExceptionMode =
  | {
    deviceId: number;
    mode: ExceptionMode;
  }
  | {
    isAllDevices: boolean;
    mode: ExceptionMode;
  };

export function setExceptionMode(param: TSetExceptionMode) {
  return axios.post(`${idsecureAPI}/devices/exceptionMode`, param);
}

export async function setDeviceFactoryConfig(id: number) {
  try {
    await axios.post(`${idsecureAPI}/devices/${id}/factoryReset`);
  } catch (e) {
    switch (e.response.status) {
      case 404:
        throw new Error('DEVICE.DEVICE_NOT_FOUND');

      default:
        throw new Error('WENT_WRONG');
    }
  }
}

export async function rebootDevice(id: number) {
  try {
    await axios.post(`${idsecureAPI}/devices/${id}/reboot`);
  } catch (e) {
    throw new Error('TOAST.DEVICE.REBOOT_ERROR');
  }
}

export async function syncDevice(id: number) {
  try {
    await axios.post(`${idsecureAPI}/devices/${id}/fullsync`);
  } catch (e) {
    throw new Error('TOAST.DEVICE.REBOOT_ERROR');
  }
}

type TSetDeviceAdvancedConfig = {
  beepEnabled: boolean;
};

export async function setDeviceAdvancedConfig(id: number, param: TSetDeviceAdvancedConfig) {
  try {
    await axios.post(`${idsecureAPI}/devices/${id}/advancedConfig`, param);
  } catch (e) {
    switch (e.response.status) {
      case 404:
        throw new Error('DEVICE.DEVICE.NOT_FOUND');

      default:
        throw new Error('WENT_WRONG');
    }
  }
}

type TGetDeviceAdvancedConfigResponse = {
  message: number;
  success: boolean;
  data: TGetDeviceAdvancedConfigData;
};

export type TGetDeviceAdvancedConfigData = {
  deviceId: number;
  beepEnabled: boolean;
};

export async function getDeviceAdvancedConfig(id: number) {
  try {
    const { data } = await axios.get<TGetDeviceAdvancedConfigResponse>(`${idsecureAPI}/devices/${id}/advancedConfig`);
    return data;
  } catch (e) {
    switch (e.response.status) {
      case 404:
        return {
          data: {
            deviceId: 0,
            beepEnabled: false
          },
          message: 1,
          success: true
        };

      default:
        throw new Error('WENT_WRONG');
    }
  }
}

export async function stopAlarm(id: number) {
  try {
    await axios.post(`${idsecureAPI}/devices/${id}/stopAlarm`);
  } catch (e) {
    throw new Error('WENT_WRONG');
  }
}
