import axios from 'axios'


type paylaodPost = {
  description: string
}

class SIPService {
  private idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL
  private endpoint_url = "/devices/sip_users?serial=0Y0100/000001"

  async checkCall() {
    return axios.get(`http://test.idbell.idsecure.com.br:6070${this.endpoint_url}`)
    // return axios.get(`${this.idsecureAPI}${this.endpoint_url}`)
  }



}

export const sipService = new SIPService()