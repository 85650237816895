import React, { useCallback, useState } from 'react';
import { Button, Col, Container, Modal, Row, Table } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { UPLOAD_CSV } from 'src/services/downloadCSV';
import exemplo from './exemplo-bloqueio.csv';


import { execToast } from './Toast';

import { setTimeout } from 'timers';



import './UploadCSVBrockPersonStyles.sass'
import { Loading } from 'src/_metronic/layout/components/Loading';

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};
export function UploadCSVehicles(props: any) {


  const [isLoading, setIsLoading] = useState(false)

  const intl = useIntl();
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [switchSectionContent, setSwitchSectionContent] = useState(false);


  const [viewBodyModal, setViewBoryModal] = useState<'file' | 'table'>('file')
  const [tableBodyValues, setTableBodyValues] = useState<{
    id: number,
    nameInDb: string,
    nameInList: string
  }[]>([])

  const [tableValuesInError, setTableValuesInError] = useState<any[]>([])

  const saveFile = (e: any) => {
    setFile(e);
    setFileName(e.name);
  };

  const uploadFile = async (confirm = false) => {
    setIsLoading(true)
    setViewBoryModal('table')

    setSwitchSectionContent(false);
    // props.onHide()
    execToast('success', intl.formatMessage({ id: 'PERSONS.FINGERPRINTS.MODAL.PROCESSING' }), 3000);

    try {

      const path = '/vehicles/import'
      const retorno = await UPLOAD_CSV(confirm, file, fileName, confirm, path, false, false);

      setTimeout(async () => {
        try {
          if (confirm) {
            execToast('success', intl.formatMessage({ id: 'IMPORT.CONFIRM.TITLE' }), 3000);
            (window as any).vehiclesRequest()
            props.onHide();
            return
          } else {
            setViewBoryModal('table')



            if (retorno.data && retorno?.data.data) {

              if (Array.isArray(retorno.data.data) && retorno.data.data.length === 0) {
                execToast('success', intl.formatMessage({ id: 'IMPORT.CONFIRM.TITLE' }), 3000);

                (window as any).vehiclesRequest()

                props.onHide();
              }

              setTableBodyValues(retorno?.data.data)
              const importErrors = retorno?.data.data.filter((i: any) => i.error)


              setTableValuesInError(importErrors)
            } else {

              if (retorno.data === '' || retorno.data.length === 0) {
                execToast('success', intl.formatMessage({ id: 'IMPORT.CONFIRM.TITLE' }), 3000);

                (window as any).vehiclesRequest()
                props.onHide();
              } else {
                setTableBodyValues([])
                // errorInImport(retorno)
              }


            }
          }
          setIsLoading(false)
        } catch (e) {
          execToast('error', intl.formatMessage({ id: 'TOAST.UPLOAD.ERROR' }), 3000);
          setIsLoading(false)
          setViewBoryModal('file')
        }
      }, 1000)

    } catch (ex) {
      setIsLoading(false)
      setViewBoryModal('file')
      // props.onHide();
      execToast('error', intl.formatMessage({ id: 'TOAST.UPLOAD.ERROR' }), 3000);
      console.error(ex);
    }
  };

  function errorInImport(retorno: any) {
    // console.log(retorno)

    if (!retorno.response) {
      return
    }

    if (retorno.response.status === 422) {
      const message = retorno.response.data.message

      if (typeof message === "string" && message.includes('461')) {
        execToast('error', intl.formatMessage({ id: 'TOAST.PERSON.ERROR.454' }) + message.split('|').filter((_: string, index: number) => index > 0), false);
      }

      if (typeof message === "string" && message.includes('462')) {
        execToast('error', message.split('|').filter((_: string, index: number) => index > 0) + ' ' + intl.formatMessage({ id: 'TOAST.PERSON.ERROR.462' }), false);
      }

      setViewBoryModal('file')
    }

    const msg = retorno.response.data.message
    if (msg === "453") {
      execToast('error', intl.formatMessage({ id: 'TOAST.PERSON.DOCUMENT' }), false);
      setViewBoryModal('file')
      return
    }
  }

  function t(value: string) {
    return intl.formatMessage({ id: value })
  }



  const onDrop = useCallback((acceptedFiles: any[]) => {
    saveFile(acceptedFiles[0]);
    setFileName(acceptedFiles[0].name);
    setSwitchSectionContent(true);
  }, []);




  return (
    <Modal
      {...props}
      aria-labelledby='contained-modal-title-vcenter'
      size='lg'
      onHide={() => {

        props.onHide();
      }}>
      <Modal.Header closeButton={true}>
        <Modal.Title id='contained-modal-title-vcenter'>
          {intl.formatMessage({ id: 'PAGE.LIST.VEHICLE.TITLE.IMPORT' })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='show-grid'>

        {
          viewBodyModal === 'file' && (
            <Container>

              <Row>
                {switchSectionContent ? (
                  <section className='container' style={{ padding: '0 11px' }}>
                    <h5>{intl.formatMessage({ id: 'IMPORT.PERSON.MODAL.SUCESS-MENSAGE' })}</h5>
                    <p>{fileName}</p>
                  </section>
                ) : (
                  <>
                    <Row>
                      <Col xs={8} md={12} className='p-5'>
                        <span>
                          <strong>{t('LIST.BLOCK.IMPORT.ALERT')}: </strong>
                          {t("PAGE.VEHICLE.IMPORT.DESC.COLUMN")}
                          <strong>{t("REGISTER.PERSON")}</strong> {t('PAGE.VEHICLE.IMPORT.DESC.COLUMN.INFO')}
                          <strong>ID</strong> {t('PAGE.VEHICLE.IMPORT.DESC.COLUMN.INFO.CASE')}
                          <strong>{t('COMPANIES.NAME')}</strong> {t('PAGE.VEHICLE.IMPORT.DESC.COLUMN.INFO.NAME')} <strong>{t('PAGE.VEHICLE.IMPORT.DESC.COLUMN.INFO.NAME.companie')}</strong> </span>

                      </Col>
                    </Row>

                    <section className='container' style={{ padding: '0 11px' }}>
                      <h5>{intl.formatMessage({ id: 'IMPORT.PERSON.SUBMIT.TITLE' })}</h5>
                      <Dropzone
                        onDrop={onDrop}
                        maxFiles={1}
                        multiple={false}
                        maxSize={268435456} //250BM
                        accept={{
                          'application/zip': ['.zip'],
                          'application/octet-stream': ['.zip', '.csv'],
                          'text/csv': ['.csv']
                        }}>
                        {({ getRootProps, getInputProps, isFocused, isDragAccept, isDragReject }) => (
                          <div
                            {...getRootProps({
                              className: 'dropzone',
                              style: {
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '30px',
                                borderWidth: 2,
                                borderRadius: 2,
                                borderColor: '#eeeeee',
                                borderStyle: 'dashed',
                                backgroundColor: '#fafafa',
                                color: '#bdbdbd',
                                outline: 'none',
                                transition: 'border .24s ease-in-out',
                                ...(isFocused ? focusedStyle : {}),
                                ...(isDragAccept ? acceptStyle : {}),
                                ...(isDragReject ? rejectStyle : {})
                              }
                            })}>
                            <input {...getInputProps()} />
                            <p className='m-0'>{intl.formatMessage({ id: 'IMPORT.PERSON.DRAGDROP' })}</p>
                          </div>
                        )}
                      </Dropzone>
                    </section>
                  </>

                )}
              </Row>
            </Container>
          )
        }

        {
          viewBodyModal === 'table' && (
            <div>


              {
                isLoading ? <Loading /> : (
                  <>
                    <Container>
                      <Row>
                        <h5>{intl.formatMessage({ id: tableValuesInError.length ? 'IMPORT.BODY.H5.CSV.ERROR' : 'IMPORT.BODY.H5.CSV' })}</h5>
                      </Row>
                    </Container>

                    {
                      tableValuesInError.length ? (
                        <Table>
                          <thead>
                            <tr>
                              <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                                {intl.formatMessage({ id: 'IMPORT.TABLE.COLUMN.ID' })}
                              </th>
                              <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                                {intl.formatMessage({ id: 'NAME_LABEL' })}
                              </th>
                              <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                                {intl.formatMessage({ id: 'BADLOGIN_FILTER_ERROS' })}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              tableValuesInError.map(item => (

                                <tr key={item.id}>
                                  <td>{item.id}</td>
                                  <td>{item.name}</td>
                                  <td>{item.error}
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      ) : (
                        <Table>
                          <thead>
                            <tr>
                              <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                                {intl.formatMessage({ id: 'IMPORT.TABLE.COLUMN.ID' })}
                              </th>
                              <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                                {intl.formatMessage({ id: 'IMPORT.TABLE.COLUMN.CURRENT' })}
                              </th>
                              <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                                {intl.formatMessage({ id: 'IMPORT.TABLE.COLUMN.UPDATE' })}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              tableBodyValues.map(item => (

                                <tr key={item.id}>
                                  <td>{item.id}</td>
                                  <td>{item.nameInDb}</td>
                                  <td>{item.nameInList}
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      )
                    }

                  </>
                )
              }

            </div>
          )
        }



      </Modal.Body>
      <Modal.Footer>

        <Button variant='primary' disabled={!file} onClick={() => {
          uploadFile(viewBodyModal === 'table' ? true : false)
        }}>
          {viewBodyModal === 'table' ? intl.formatMessage({ id: 'IMPORT.BUTTON.CONFIRM' }) : intl.formatMessage({ id: 'PERSON.NEWPERSON_UPLOAD' })}
        </Button>

        <Button
          type='button'
          variant='secondary'
          onClick={() => {

            props.onHide();
          }}>
          {intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
