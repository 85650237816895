import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ContentPage } from 'src/app/components/contentPage';
import { List } from './components/list';

import { handleCulture } from 'src/utils/culture';
import { useLang } from 'src/_metronic/i18n';
import { IoIosEye } from 'react-icons/io';
import { toAbsoluteUrl } from 'src/_metronic/_helpers';
import SVG from 'react-inlinesvg';
import { Button, Modal } from 'react-bootstrap';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { listBlockPerson } from 'src/services/listBlockPerson.service';
import { defaultDateTime } from 'src/utils/defaultDateTime';

let debounce: NodeJS.Timeout
export function ListBlockPeople() {
  const intl = useIntl()
  const lang = useLang();

  const [tablePaginationOptions, setTablePaginationOptions] = useState({
    custom: true,
    totalSize: 0,
    sizePerPageList: window.sizePerPageList,
    sizePerPage: 10,
    page: 1
  });
  const [status, setStatus] = useState<number>(1)
  const [search, setSearch] = useState<string>('')
  const [list, setList] = useState<any[]>([])

  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModalDeleteLogic, setOpenModalDeleteLogic] = useState(false)
  const [openModalActivced, setOpenModalActivced] = useState(false)
  const [itemSelected, setItemSelected] = useState({
    name: '',
    id: 0
  })

  function headerTools() {
    return (
      <>

        <div style={{ width: '8vw', marginLeft: '10px' }}>
          <input
            onChange={({ target }) => {
              if (debounce) clearTimeout(debounce);
              debounce = setTimeout(() => {
                setSearch(target.value);
              }, 500);
            }}
            className='form-control'
            type='text'
            placeholder={intl.formatMessage({ id: 'PAGE.BLUEPRINT.LIST_INPUT.SEARCH' })}
          />
        </div>

        <div style={{ display: 'flex', gap: '5px' }}>
          <Link
            title={intl.formatMessage({ id: 'PAGE.LIST.BLOCKING.REASONS.ADD' })}
            type='button'
            className='btn btn-primary ml-5 mr-2'
            to='/blocked-people/create'>
            {intl.formatMessage({ id: 'PAGE.LIST.BLOCKING.REASONS.ADD' })}
          </Link>
        </div>


      </>
    )
  }

  function handleQuantityItensInTable(value: number) {
    setTablePaginationOptions(values => {
      return {
        ...values,
        sizePerPage: value
      }
    })
  }

  function handlePage(value: number) {
    setTablePaginationOptions(values => {
      return {
        ...values,
        page: value
      }
    })
  }

  function RenderAction(item: any) {

    return (
      <>

        <Link className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3' to={`/blocked-people/edit/${item.id}`}>
          <span className='svg-icon svg-icon-md svg-icon-primary'>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
          </span>
        </Link>

        <a
          className='btn btn-icon btn-light btn-hover-danger btn-sm mx-3'
          onClick={() => {
            setItemSelected({
              id: item.id,
              name: item.person.name
            })

            setOpenModalDelete(true)
          }}>
          <span className='svg-icon svg-icon-md svg-icon-danger'>
            <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
          </span>
        </a>
      </>
    );



  }

  async function load() {
    const params = {
      value: search,
      culture: handleCulture(lang),
      pageSize: tablePaginationOptions.sizePerPage,
      pageNumber: tablePaginationOptions.page,
      status: status,
      sortOrder: 'desc',
      sortField: 'id',
    }

    try {
      const { data } = await listBlockPerson.list(params)

      const values = data.data.data.map((item: any) => {
        return {
          id: item.id,
          person: item.person.name,
          dtEnd: defaultDateTime.labelDateTime(item.dtEnd),
          dtStart: defaultDateTime.labelDateTime(item.dtStart),
          blockReasonDescription: item.blockReasonDescription,
          actions: RenderAction(item)
        }
      })

      setList(values)
      setTablePaginationOptions(values => {
        return {
          ...values,
          totalSize: data.data.total
        }
      })

    } catch (e) {

    }
  }


  useEffect(() => {
    window.controlID.modules.blockedPeople.service.load = () => { load() }
    load()
  }, [search, status, tablePaginationOptions.page, tablePaginationOptions.sizePerPage])

  return (
    <ContentPage
      title={intl.formatMessage({ id: 'PAGE.LIST.BLOCKING.TITLE' })}
      addItemTitle={intl.formatMessage({ id: 'PAGE.LIST.BLOCKING.TITLE' })}
      linkPageCreate="/blocked-people/create"
      LongFilters={{
        culture: handleCulture(lang),
        pageNumber: tablePaginationOptions.page,
        pageSize: tablePaginationOptions.sizePerPage,
        sortField: 'description',
        sortOrder: 'desc',
        status: status
      }}
      logUrl='active'
      logNameFile='active'
      csvImport={true}
      logTotalItens={tablePaginationOptions.totalSize}
      logHeaders={[]}
      plusOptionsInToobar={true}
      plusOptionsRender={headerTools()}
      disabledOptionsHeader={false}
    >
      <List
        tablePaginationOptions={tablePaginationOptions}
        handleQuantityItensInTable={handleQuantityItensInTable}
        handlePage={handlePage}
        intl={intl}
        values={list}
      />

      <Modal show={openModalDelete} onHide={() => setOpenModalDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {intl.formatMessage({ id: 'TOAST.DELETE_BUTTON' })} : {itemSelected.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {intl.formatMessage({
            id: 'PAGE.LIST.BLOCKING.MODAL.DELETE'
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              marginRight: '20%'
            }}
            variant='secondary'
            onClick={() => {
              setOpenModalDelete(false);
            }}>
            {intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}
          </Button>
          <button
            type="button"
            className='btn btn-outline-danger'
            onClick={async () => {
              setOpenModalDeleteLogic(true)
              setOpenModalDelete(false);
            }}>
            {intl.formatMessage({ id: 'PERMANENTLY_DELETE' })}
          </button>
          <Button
            variant='danger'
            onClick={async () => {

              setOpenModalDelete(false);
              await listBlockPerson.delete(itemSelected.id);
              load()
              execToast('success', intl.formatMessage({ id: 'PAGE.BLUEPRINT.TOAST_INATIVE' }), 3000);
            }}>
            {intl.formatMessage({ id: 'TOAST.DELETE_BUTTON' })}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={openModalActivced} onHide={() => setOpenModalActivced(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {intl.formatMessage({ id: 'TOAST.ENABLE' })} : {itemSelected.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {intl.formatMessage({
            id: 'PAGE.LIST.BLOCKING.MODAL.ATIVE'
          })}{' '}
          {itemSelected.name} ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              setOpenModalActivced(false);
            }}>
            {intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}
          </Button>
          <Button
            variant='primary'
            onClick={async () => {

              setOpenModalActivced(false);
              await listBlockPerson.active(itemSelected.id)
              load();
              execToast('success', intl.formatMessage({ id: 'PAGE.BLUEPRINT.TOAST_ATIVE' }), 3000);
            }}>
            {intl.formatMessage({ id: 'TOAST.ENABLE' })}
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={openModalDeleteLogic} onHide={() => setOpenModalDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "rgb(246, 78, 96)" }}>
            {intl.formatMessage({ id: 'ARE_YOU_SURE_YOU_WANT_TO_PERMANENTLY_DELETE_IT' })} : {itemSelected.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {intl.formatMessage({
            id: 'THIS_ACTION_CANNOT_BE_UNDONE'
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              marginRight: '20%'
            }}
            variant='secondary'
            onClick={() => {
              setOpenModalDeleteLogic(false);
            }}>
            {intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}
          </Button>


          <button
            type="button"
            className='btn btn-outline-danger'
            onClick={async () => {

              setOpenModalDeleteLogic(false);
              await listBlockPerson.delete(itemSelected.id);
              load()
              execToast('success', intl.formatMessage({ id: 'PAGE.BLUEPRINT.TOAST_INATIVE' }), 3000);
            }}>
            {intl.formatMessage({ id: 'TOAST.DELETE_BUTTON' })}
          </button>


          <Button
            variant='danger'
            onClick={async () => {

              setOpenModalDeleteLogic(false);
              await listBlockPerson.deleteLogic(itemSelected.id);
              load()
              execToast('success', intl.formatMessage({ id: 'PAGE.BLUEPRINT.TOAST_INATIVE' }), 3000);
            }}>
            {intl.formatMessage({ id: 'PERMANENTLY_DELETE' })}
          </Button>
        </Modal.Footer>
      </Modal>

    </ContentPage>
  )
}