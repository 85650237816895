export const typeCustomInputsEnums = (value: number) => {

  switch (value) {
    case 5: {
      return 'Alphanumeric'
    }

    case 6: {
      return 'TextAndNumber'
    }

    case 1: {
      return 'Number'
    }

    default: {
      return 'Text'
    }
  }

}

export const typeCustomInputsElement = (value: number) => {

  switch (value) {
    case 5: {
      return 'text'
    }

    case 1: {
      return 'number'
    }

    default: {
      return 'text'
    }
  }

}