import React, { useEffect, useRef, useState } from 'react'

import { UserAgent, Inviter, Registerer, SessionState } from "sip.js";
import { HubConnectionBuilder } from '@microsoft/signalr'

import { BiMicrophone } from "react-icons/bi";

import './modalReceiver.sass'
import { IoCloseCircle } from 'react-icons/io5';
import Axios from 'axios';
import { sipService } from 'src/services/SIP.service';


let newUserAgent: any = null;
const API_IDBELL = process.env.REACT_APP_IDSECURE_IDBELL;
let user = ''
let listIds = ''
let blockOpen = false

let RealTimer: NodeJS.Timeout | null;
export function ModalReceiver() {
  const remoteVideoRef = useRef<HTMLVideoElement>(null);
  const localVideoRef = useRef<HTMLVideoElement>(null);

  const [isConference, setIsConference] = useState(false)

  const [logs, setLogs] = useState<any[]>([]);
  const [userAgent, setUserAgent] = useState<any>(null);
  const [session, setSession] = useState<any>(null);
  const [connected, setConnected] = useState(false);
  const [target, setTarget] = useState('');
  const [conferenceName, setConferenceName] = useState('conference');


  // const remoteVideoRef = useRef(null);


  const updateLogs = (message: any) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    // console.log(message); // Log extra para depuração no console
  };

  const initializeClient = () => {
    if (connected) {
      updateLogs("Cliente já conectado.");
      return;
    }


    const url = `sip:${user}@test.idbell.idsecure.com.br`
    //@ts-ignore
    const uri = UserAgent.makeURI(url);
    const transportOptions = {
      server: 'wss://test.idbell.idsecure.com.br:7089/ws',
    };
    const userAgentOptions = {
      uri: uri,
      transportOptions: transportOptions
    };

    const ua = new UserAgent(userAgentOptions);

    ua.start().then(() => {
      setUserAgent(ua);
      setConnected(true);
      updateLogs("User Agent iniciado e registrado.");

    }).catch((error) => {

      updateLogs(`Erro ao iniciar o User Agent: ${error.message}`);
    });

    ua.delegate = {
      onDisconnect: () => {
        updateLogs("Conexão WebSocket encerrada.");
        setConnected(false);
      },
      onConnect: () => {
        updateLogs("Conexão WebSocket estabelecida.");
        setConnected(true);


      }
    };
  };

  const handleCall = () => {
    if (!target) {
      updateLogs("Por favor, insira um endereço para a chamada.");
      return;
    }
    // initiateCall(target);
  };

  const handleJoinConference = () => {
    if (!conferenceName) {
      updateLogs("Por favor, insira o nome da conferência.");
      return;
    }
    // initiateCall(conferenceName);
  };

  const initiateCall = () => {
    if (userAgent) {
      const target: any = UserAgent.makeURI(`sip:conference@test.idbell.idsecure.com.br:7089`);
      const inviter: any = new Inviter(userAgent, target, {
        sessionDescriptionHandlerOptions: {
          constraints: {
            audio: true,
            video: true,
          },
        },
        extraHeaders: ['X-Device: WebRTC']
      });


      inviter.invite().then(() => {
        setSession(inviter);
        updateLogs("Chamada iniciada.");

        inviter.stateChange.addListener((state: any) => {
          if (state === SessionState.Established) {
            updateLogs("Chamada estabelecida.");

            const localStream = inviter.sessionDescriptionHandler.peerConnection.getLocalStreams()[0];
            const remoteStream = inviter.sessionDescriptionHandler.peerConnection.getRemoteStreams()[0];

            if (localVideoRef.current && localStream) {
              localVideoRef.current.srcObject = localStream;
            } else {
              updateLogs("Erro: Referência de vídeo local não encontrada.");
            }

            if (remoteVideoRef.current && remoteStream) {
              remoteVideoRef.current.srcObject = remoteStream;
            } else {
              updateLogs("Erro: Referência de vídeo remoto não encontrada.");
            }
          }
        });
      }).catch((error: any) => {
        updateLogs(`Erro ao iniciar chamada: ${error.message}`);
      });
    } else {
      updateLogs("Erro: User Agent não está inicializado.");

      // setTimeout(initiateCall, 1000)
    }
  };

  const handleHangup = () => {
    if (session) {
      if (session.state !== SessionState.Terminated) {
        session.bye().then(() => {
          setSession(null);
          updateLogs("Chamada encerrada.");
          setUserAgent(null)
        }).catch((error: any) => {
          updateLogs(`Erro ao encerrar chamada: ${error.message}`);
        });
      } else {
        updateLogs("A sessão já foi encerrada.");
        setSession(null);
      }
    } else {
      updateLogs("Nenhuma sessão ativa para encerrar.");
    }
  };

  const handleAcceptCall = () => {
    if (session) {
      if (session.state === SessionState.Established) {
        updateLogs("Chamada já foi atendida.");
      } else {
        session.accept().then(() => {
          updateLogs("Chamada atendida.");
        }).catch((error: any) => {
          updateLogs(`Erro ao atender a chamada: ${error.message}`);
        });
      }
    } else {
      updateLogs("Nenhuma chamada para atender.");
    }
  };


  // useEffect(() => {
  //   let connection = new HubConnectionBuilder()
  //     .withUrl("http:localhost:9090/chat")
  //     .build();

  //   connection.on("send", data => {
  //     console.log(data);
  //   });

  //   connection.start()
  //     .then(() => connection.invoke("send", "Hello"));
  // })

  function RealTime() {
    if (RealTimer) {
      clearTimeout(RealTimer);
    }

    RealTimer = setTimeout(async () => {
      try {
        if (user !== '') {
          RealTime()
          return
        }
        const { data } = await sipService.checkCall()

        if (data.length === 0) {
          user = ''
          RealTime()
          return
        }

        // console.log({ data: data[data.length - 1] })
        const userSet = data[data.length - 1]
        user = userSet

        if (data.toString() === listIds) {
          user = ''

        } else {
          listIds = data.toString()
          //@ts-ignore
          window.sip.open()
        }


        RealTime()

      } catch (e) {
        RealTime()
      }
    },
      5000
    );
  }


  useEffect(() => {
    console.log(logs)
  }, [logs])

  useEffect(() => {
    //@ts-ignore
    // window.sip.open()

    // RealTime()
  }, [])

  // useEffect(() => {

  //   if (userAgent) {
  //     initiateCall()
  //   }
  // }, [userAgent])

  //@ts-ignore
  // window.ac = {
  //   add: () => {
  //     initializeClient()
  //     // initiateCall()
  //   },
  //   initiateCall
  // }

  //@ts-ignore
  window.sip = {
    open: () => {
      document.querySelector("#MODAL_RECEIVER")?.classList.add('view')
    },
    close: () => {
      user = ''
      document.querySelector("#MODAL_RECEIVER")?.classList.remove('view')
      setIsConference(false)

      handleHangup()

      setConnected(false)
    },
  }

  return (

    <section id="MODAL_RECEIVER">

      <span className='closer' onClick={() => {
        //@ts-ignore
        window.sip.close()
      }}>
        <IoCloseCircle color="#BE6664" size={24} />
      </span>
      <div className='content'>
        <div className='title'>
          <span>Recebendo Chamada</span>
        </div>

        {
          isConference ? (
            <div className='content-video'>
              <video ref={remoteVideoRef} autoPlay />
              <video ref={localVideoRef} autoPlay muted style={{ display: 'none' }} />
            </div>
          ) : (
            <div className='content-video'>
              <div></div>
            </div>
          )
        }

        {
          isConference ? (
            <div className='control'>
              <button className='btn btn-danger' onClick={() => {
                //@ts-ignore
                window.sip.close()
              }}>
                Encerrar
              </button>

              <button className='btn btn-primary'>Abrir porta</button>
            </div>
          ) : (
            <div className='control'>
              <button className='btn btn-primary' onClick={() => {
                setIsConference(true)

                initializeClient()
                // setTimeout(() => { initiateCall() }, 1000)
              }}>Atender</button>
            </div>
          )
        }



        <div className='footer'>

        </div>
      </div>
    </section>

  )
}
