import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { getDownloadCSV, getDownloadList } from 'src/services/downloadCSV';
import moment from 'moment';
import { execToast } from './Toast';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from 'src/_metronic/_helpers';
import { Button, ListItemIcon, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { FaFile, FaFileArchive, FaFileCsv, FaFilePdf } from 'react-icons/fa';
import { getFileRequest } from 'src/services/getFiles';
import { nameAndTypePDFs } from 'src/utils/getPDF';
import { useHistory } from 'react-router-dom';

const fileDownload = require('js-file-download');
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));
export function DownloadExportList({
  filtro,
  api,
  headers,
  columns,
  onClick,
  nomeArquivo,
  linkApiViewHTML,
}: {
  filtro: any;
  api: string;
  linkApiViewHTML?: string;
  headers: string[];
  columns?: string[];
  onClick?: () => void;
  nomeArquivo?: string;
}) {
  const htmlRef = useRef<HTMLAnchorElement>(null)
  const classes = useStyles();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const history = useHistory()
  function downloadCSV(e: any) {
    e.preventDefault();
    setIsLoading(true);

    getDownloadCSV(api, filtro, headers, columns)
      .then(async (response: any) => {
        const textCSV = await response.data.text();
        let rows = textCSV.split('\n');
        rows[0] = rows[0].normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        rows = rows.join('\n');
        //@ts-ignore
        fileDownload(rows, `${nomeArquivo.replace(' ', '-')}_${moment().format('YYYYMMDD_HHmm')}.csv`);
        execToast('success', intl.formatMessage({ id: 'TOAST.DOWNLOAD.SUCCESS' }), 3000);
      })
      .catch((error: any) => {
        console.error(error.response);

        if (error.response?.data?.message === '445') {
          execToast('error', intl.formatMessage({ id: 'API_RESPONSE_LIMIT_REPORT_ITENS' }), 3000);
          return
        }

        execToast('error', intl.formatMessage({ id: 'TOAST.DOWNLOAD.ERROR' }), 3000);
      })
      .finally(() => {
        setIsLoading(false);
        if (onClick) onClick();
      });
  }

  function htmlView_(e: any) {
    e.preventDefault();
    setIsLoading(true);

    getDownloadList({ linkApiViewHTML: linkApiViewHTML, filtro, headers, columns, title: nomeArquivo })
      .then(async (response: any) => {

        const blb = new Blob([response.data], { type: 'text/plain' });
        const reader = new FileReader();

        reader.addEventListener('loadend', (e: any) => {
          var uri = 'data:text/html,' + encodeURIComponent(e.target.result);
          var newWindow: any = window.open(uri);
          newWindow.document.write(e.target.result);
        });

        reader.readAsText(blb);

      })
      .catch((error: any) => {


        if (error.response?.data?.message === '445') {
          execToast('error', intl.formatMessage({ id: 'API_RESPONSE_LIMIT_REPORT_ITENS' }), 3000);
          return
        }

        execToast('error', intl.formatMessage({ id: 'TOAST.DOWNLOAD.ERROR' }), 3000);
      })
      .finally(() => {
        setIsLoading(false);
        if (onClick) onClick();
      });
  }

  function htmlView() {

    setIsLoading(true);

    getDownloadCSV(`${api}`, { ...filtro, exportType: 'HTML' }, headers, columns)
      .then(async (response: any) => {

        const blb = new Blob([response.data], { type: 'text/plain' });
        // const reader = new FileReader();

        // reader.addEventListener('loadend', (e: any) => {
        //   var uri = 'data:text/html,' + encodeURIComponent(e.target.result);
        //   var newWindow: any = window.open(uri);
        //   newWindow.document.write(e.target.result);
        // });

        // reader.readAsText(blb);
        const urlHtml = URL.createObjectURL(blb);

        htmlRef.current?.setAttribute('href', urlHtml)
        htmlRef.current?.setAttribute('download', 'Relatorio.html')

        htmlRef.current?.click()

      })
      .catch((error: any) => {
        console.error(error.response);

        if (error.response?.data?.message === '445') {
          execToast('error', intl.formatMessage({ id: 'API_RESPONSE_LIMIT_REPORT_ITENS' }), 3000);
          return
        }

        execToast('error', intl.formatMessage({ id: 'TOAST.DOWNLOAD.ERROR' }), 3000);
      })
      .finally(() => {
        setIsLoading(false);
        if (onClick) onClick();
      });
  }

  async function requestDownloadPDF(e: any) {
    e.preventDefault();
    setIsLoading(true);

    // reportType
    //title
    const pdfInfo = nameAndTypePDFs.getNameAndType()

    filtro.reportType = pdfInfo.type
    filtro.title = intl.formatMessage({ id: pdfInfo.nameToIntl })

    await getFileRequest.PDF(api, filtro, headers, columns)
    execToast('success', intl.formatMessage({ id: 'EXPORT.PDF.SOLICITATION.SUCCESS' }), 3000);

    setIsLoading(false);
    if (onClick) onClick();


    history.push('/pdfdownload')

    // getDownloadCSV(api, filtro, headers, columns)
    //   .then(async (response: any) => {
    //     const textCSV = await response.data.text();
    //     let rows = textCSV.split('\n');
    //     rows[0] = rows[0].normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    //     rows = rows.join('\n');
    //     //@ts-ignore
    //     fileDownload(rows, `${nomeArquivo.replace(' ', '-')}_${moment().format('YYYYMMDD_HHmm')}.csv`);
    //     execToast('success', intl.formatMessage({ id: 'TOAST.DOWNLOAD.SUCCESS' }), 3000);
    //   })
    //   .catch((error: any) => {
    //     console.error(error.response);

    //     if (error.response?.data?.message === '445') {
    //       execToast('error', intl.formatMessage({ id: 'API_RESPONSE_LIMIT_REPORT_ITENS' }), 3000);
    //       return
    //     }

    //     execToast('error', intl.formatMessage({ id: 'TOAST.DOWNLOAD.ERROR' }), 3000);
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //     if (onClick) onClick();
    //   });
  }
  //exportType

  return (
    // <MenuItem onClick={downloadCSV}>
    //   {intl.formatMessage({id: 'FILTER.BUTTON.CSV_DOWNLOAD'})}
    //   {isLoading ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : <SVG src={toAbsoluteUrl('/media/svg/icons/Files/DownloadedFile.svg')} />}
    // </MenuItem>
    <>

      <MenuItem style={{ gap: '10px' }} onClick={requestDownloadPDF}>
        <span style={{ color: '#abd4ff', paddingLeft: '2px' }}>
          <FaFilePdf size='22px' />
        </span>

        <Typography variant='inherit' noWrap>
          {intl.formatMessage({ id: 'EXPORT.MENU.PDF' })}
        </Typography>
      </MenuItem>
      <MenuItem style={{ gap: '10px' }} onClick={downloadCSV}>
        <span style={{ color: '#abd4ff', paddingLeft: '2px' }}>
          <FaFileCsv size='22px' />
        </span>

        <Typography variant='inherit' noWrap>
          {intl.formatMessage({ id: 'FILTER.BUTTON.CSV_DOWNLOAD' })}
        </Typography>
      </MenuItem>
      <MenuItem style={{ gap: '10px' }} onClick={(e: any) => htmlView()}>
        <span style={{ color: '#abd4ff', paddingLeft: '2px' }}>
          <FaFile size='22px' />
        </span>

        <Typography variant='inherit' noWrap>
          {intl.formatMessage({ id: 'OPTION.VIEW.HTML.FILE' })}
        </Typography>

      </MenuItem>
      <a ref={htmlRef} href="" style={{ display: 'none' }}>arquivo</a>
      {/* <MenuItem style={{gap: '10px'}} onClick={(e: any) => downloadPDF(e, true)}>
        <span style={{color: '#abd4ff', paddingLeft: '2px'}}>
          <FaFilePdf size='22px' />
        </span>

        <Typography variant='inherit' noWrap>
          {intl.formatMessage({id: 'Exportar PDF (Paisagem)'})}
        </Typography>
      </MenuItem> */}
    </>
  );
}
