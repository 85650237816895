import axios from 'axios'


type paylaodPost = {
  personId: number,
  blockReasonId: number,
  dtStart: string,
  dtEnd: string
}

class ListBlockPerson {
  private idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL
  private endpoint_url = "/blockedPerson"

  async save(payload: paylaodPost) {
    return axios.post(`${this.idsecureAPI}${this.endpoint_url}`, payload)
  }

  async edit(payload: any) {
    return axios.put(`${this.idsecureAPI}${this.endpoint_url}`, payload)
  }

  async list(params: any) {
    return axios.get(`${this.idsecureAPI}${this.endpoint_url}`, {
      params
    })
  }
  async getById(id: number) {
    return axios.get(`${this.idsecureAPI}${this.endpoint_url}/${id}`)
  }

  async delete(id: number) {
    return axios.delete(`${this.idsecureAPI}${this.endpoint_url}/${id}`)
  }

  async deleteLogic(id: number) {
    return axios.delete(`${this.idsecureAPI}${this.endpoint_url}/${id}`,
      {
        params: {
          erase: true
        }
      }
    )
  }

  async active(id: number) {
    return axios.put(`${this.idsecureAPI}${this.endpoint_url}/${id}`)
  }


}

export const listBlockPerson = new ListBlockPerson()